export enum BlogCategory {
  Company = 'company',
  Engineering = 'engineering',
  Product = 'product',
  Tutorials = 'tutorials',
  Vitess = 'vitess'
}

export type ClusterSize = {
  name: ClusterSizeName
  slug: ClusterSizeSlug
  cpu: number
  cpu_string: string
  gb_ram: number
}

type ClusterSizeName =
  | 'PS-10'
  | 'PS-20'
  | 'PS-40'
  | 'PS-80'
  | 'PS-160'
  | 'PS-320'
  | 'PS-400'
  | 'PS-640'
  | 'PS-700'
  | 'PS-900'
  | 'PS-1280'
  | 'PS-1400'
  | 'PS-1800'
  | 'PS-2100'
  | 'PS-2560'
  | 'PS-2700'
  | 'PS-2800'

export enum ClusterSizeSlug {
  PS_10 = 'PS_10',
  PS_20 = 'PS_20',
  PS_40 = 'PS_40',
  PS_80 = 'PS_80',
  PS_160 = 'PS_160',
  PS_320 = 'PS_320',
  PS_400 = 'PS_400',
  PS_640 = 'PS_640',
  PS_700 = 'PS_700',
  PS_900 = 'PS_900',
  PS_1280 = 'PS_1280',
  PS_1400 = 'PS_1400',
  PS_1800 = 'PS_1800',
  PS_2100 = 'PS_2100',
  PS_2560 = 'PS_2560',
  PS_2700 = 'PS_2700',
  PS_2800 = 'PS_2800'
}

export type Page = {
  page: string | null
  root: string
  url: string
}

export type Pagination = {
  hasNext: boolean
  hasPrev: boolean
  page: number
  totalPages: number
  url: string
}

export type Region = {
  cluster_rates: Record<ClusterSizeSlug, string>
  display_name: string
  location: string
  provider: string
  slug: string
}

export type Theme = 'ascii' | 'legacy'
